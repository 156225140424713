import { Text } from '@audi/audi-ui-react';
import styled, { keyframes } from 'styled-components';
import { isDarkTheme } from '../../utils/theme';

const fadein = keyframes` 
0% {
  height: 0;
  opacity: 0;
}
1% {
  height: auto;
  opacity: 0;
}
100% {
  height: auto;
  opacity: 1;
}
`;

const fadeout = keyframes`
0% {
  height: auto;
  opacity: 1;
}
99% {
  height: auto;
  opacity: 0;
}
100% {
  height: 0;
  opacity: 0;
}
`;

export const StickyNav = styled.nav`
  --panel-height: 0px;
  --sticky-bar-height: 0px;
  height: var(--panel-height);
  max-height: calc(100vh - var(--sticky-bar-height));
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  visibility: hidden;
  transition: 400ms ${(props) => props.theme.easing};
  transition-property: height, visibility;

  &.open {
    visibility: visible;
  }
`;

export const StickyNavContainer = styled.div`
  z-index: 90;
  height: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  background: var(${(props) => props.theme.colors.ui.inverted});
  will-change: opacity, height;
  animation-timing-function: ${(props) => props.theme.easing};
  animation-fill-mode: forwards;
  animation-duration: 0.5s;

  &.hidden {
    height: auto;
    animation-name: ${fadeout};
  }

  &.visible {
    animation-name: ${fadein};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: none;
  }
`;

export const StickyNavHeadline = styled.div`
  display: flex;
  justify-content: space-between;
  padding-block-start: var(${(props) => props.theme.responsive.spacing.m});
  padding-block-end: var(${(props) => props.theme.responsive.spacing.m});
  padding-inline-start: var(${(props) => props.theme.responsive.spacing.m});
  padding-inline-end: var(${(props) => props.theme.responsive.spacing.m});

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    padding-inline-start: 24px;
    padding-inline-end: 24px;
  }
`;

export const StickyNavHeadlineText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StickyList = styled.ul`
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  background: var(
    ${({ theme }) =>
      isDarkTheme(theme) ? theme.colors.base.grey['80'] : theme.colors.base.grey['10']}
  );
  padding-block-start: var(${(props) => props.theme.responsive.spacing.l});
  padding-block-end: var(${(props) => props.theme.responsive.spacing.l});
  padding-inline-start: var(${(props) => props.theme.responsive.spacing.m});
  padding-inline-end: var(${(props) => props.theme.responsive.spacing.m});
  list-style: outside none none;
  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    padding-inline-start: 24px;
    padding-inline-end: 24px;
  }
`;
