import { ContentFragment } from '@oneaudi/falcon-tools';

export type Content = {
  headline: string;
  lightDarkMode: THEME.LIGHT | THEME.DARK;
};

export interface HeadlessContent extends ContentFragment {
  readonly __type: string;
  readonly fields: {
    lightDarkMode: THEME.LIGHT | THEME.DARK;
  };
}

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}
