/**
 * This is the entry point for Feature Hub App integration
 */

import * as React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import { ThemeProvider } from '@audi/audi-ui-react';
import type { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@oneaudi/content-service/dist/v1';
import { ContentContextProvider } from '@oneaudi/feature-app-utils';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import {
  InPageNavigationServiceV1,
  defineInPageNavigationService,
} from '@volkswagen-onehub/audi-in-page-navigation-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { fetchI18nMessages, I18nMessages } from './i18n';
import { FeatureAppContextProvider } from './context/FeatureAppContext';
import AsyncFeatureApp from './FeatureApp';
import { FeatureAppConfig } from './demo/Integrator';
import { InPageNavigationProvider } from './context/InPageNavigationContext';
import GlobalStyles from './FeatureAppStyles';

export interface Dependencies extends FeatureServices {
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
  readonly 'audi-in-page-navigation-service': InPageNavigationServiceV1;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
}

export interface State {
  readonly i18nMessages: I18nMessages;
}

export async function createInitialState(
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1,
): Promise<State> {
  const i18nMessages = await fetchI18nMessages(localeService, i18nService);
  return { i18nMessages };
}

export type AsyncStateHolder = (() => Promise<State>) | State | undefined;

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies, FeatureAppConfig> =
  {
    dependencies: {
      featureServices: {
        'audi-content-service': '^1.0.0',
        'audi-in-page-navigation-service': '^0.0.1',
      },
      externals: {
        '@audi/audi-ui-react': '^3.4.1',
        '@feature-hub/react': '^3.5.0',
        react: '^17.0.2 || 18.2.0',
        'styled-components': '^5.3.0',
      },
    },

    optionalDependencies: {
      featureServices: {
        's2:logger': '^1.0.0',
        's2:async-ssr-manager': '^1.0.0',
        'audi-tracking-service': '^1.0.0',
        's2:serialized-state-manager': '^1.0.0',
        'audi-render-mode-service': '^1.0.0',
        'gfa:locale-service': '^1.0.0',
        'dbad:audi-i18n-service': '^1.0.0',
      },
    },

    ownFeatureServiceDefinitions: [defineInPageNavigationService({ serviceEnabled: true })],

    create: ({ featureServices }: FeatureAppEnvironment<Dependencies, void>) => {
      const {
        'audi-tracking-service': trackingService,
        's2:logger': s2logger,
        'audi-content-service': contentService,
        'gfa:locale-service': localeService,
        'dbad:audi-i18n-service': i18nService,
        's2:async-ssr-manager': asyncSsrManager,
        's2:serialized-state-manager': serializedStateManager,
        'audi-in-page-navigation-service': inPageNavigationService,
      } = featureServices;

      if (trackingService) {
        trackingService.featureAppName = 'oneaudi-feature-app-anchor-navigation';
      }

      const logger = s2logger || console;
      logger.info('Feature App created.');

      let asyncStateHolder: AsyncStateHolder;
      if (asyncSsrManager) {
        // on the server
        asyncSsrManager.scheduleRerender(
          (async () => {
            asyncStateHolder = await createInitialState(localeService, i18nService);
            serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
          })(),
        );
      } else {
        // on the client
        const serializedState = serializedStateManager?.getSerializedState();
        if (serializedState) {
          asyncStateHolder = JSON.parse(serializedState) as State;
        } else {
          logger?.warn(
            `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`,
          );
          asyncStateHolder = () => createInitialState(localeService, i18nService);
        }
      }

      return {
        render: () => {
          return (
            <ThemeProvider>
              <ContentContextProvider contentService={contentService}>
                <GlobalStyles />
                <FeatureAppContextProvider featureServices={featureServices}>
                  <InPageNavigationProvider inPageNavigationService={inPageNavigationService}>
                    <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
                  </InPageNavigationProvider>
                </FeatureAppContextProvider>
              </ContentContextProvider>
            </ThemeProvider>
          );
        },
      };
    },
  };

export default featureAppDefinition;
