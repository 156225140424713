import * as React from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';
import { RenderMode } from '@oneaudi/render-mode-service';

import { useRenderMode } from '../../context/FeatureAppContext';

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.ui.error};
  display: block;
`;
const NotificationMessage = styled.span`
  color: ${({ theme }) => theme.colors.ui.success};
  display: block;
`;

export interface WarningMessageProps {
  readonly anchors: number;
  readonly maxAnchors: number;
  readonly minAnchors: number;
}

export const WarningMessage = ({
  anchors,
  maxAnchors,
  minAnchors,
}: WarningMessageProps): JSX.Element | null => {
  if (useRenderMode() === RenderMode.DEFAULT) {
    return null;
  }

  function renderTooFew() {
    if (anchors >= minAnchors) {
      return null;
    }
    return (
      <Text as="h1" variant="order3">
        <ErrorMessage>
          Error: You configured {anchors} anchor{anchors !== 1 && 's'}. The Feature App will be
          activated after you placed at least {minAnchors} anchors with the separate “Anchor”
          Feature App on the page. It&apos;s recommended to use 4 to 6 anchors. Place this FA once
          only on the page ABOVE any Anchor FA.
        </ErrorMessage>
      </Text>
    );
  }

  function renderTooMany() {
    if (anchors <= maxAnchors) {
      return null;
    }
    return (
      <Text as="h1" variant="order3">
        <ErrorMessage>
          Warning: You configured {anchors} anchors. Only up to {maxAnchors} are displayed. It’s
          recommended to use 4 to 6 anchors. Place once only on the page ABOVE any Anchor FA.
        </ErrorMessage>
      </Text>
    );
  }

  function renderNotificaton() {
    return (
      <Text as="h1" variant="order3">
        <NotificationMessage>
          You configured {anchors} anchors. It’s recommended to use 4 to 6 anchors. Place once only
          on the page ABOVE any Anchor FA.
        </NotificationMessage>
      </Text>
    );
  }

  return renderTooFew() || renderTooMany() || renderNotificaton();
};
