import { Content, HeadlessContent } from '../../types';

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalisedContent: Content = {
      headline: content.title,
      lightDarkMode: content.fields.lightDarkMode,
    };
    return normalisedContent;
  }
  return content as Content;
};
