import React, { useMemo, JSX } from 'react';

import { I18nTexts } from './index';

interface State {
  readonly i18nMessages: I18nTexts;
}

interface ContextValue {
  readonly children: JSX.Element;
  readonly i18nMessages: I18nTexts;
}

export const I18nContext = React.createContext<State>({} as State);

export const I18nContextProvider = (props: ContextValue): JSX.Element => {
  const { children, i18nMessages } = props;

  const state: State = useMemo(() => {
    return {
      i18nMessages,
    };
  }, []);

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
};
