import * as React from 'react';
import { InPageNavigationServiceV1 } from '@volkswagen-onehub/audi-in-page-navigation-service';
import { useMemo } from 'react';

export interface FeatureAppContextState {
  readonly inPageNavigationService: InPageNavigationServiceV1;
}

export const InPageNavigationContext = React.createContext<FeatureAppContextState>(
  {} as FeatureAppContextState,
);

export interface FeatureAppContextProviderProps {
  readonly inPageNavigationService: InPageNavigationServiceV1;
  readonly children: JSX.Element;
}

export const InPageNavigationProvider = ({
  inPageNavigationService,
  children,
}: React.PropsWithChildren<FeatureAppContextProviderProps>): JSX.Element => {
  const value = useMemo(() => {
    return {
      inPageNavigationService,
    };
  }, []);
  return (
    <InPageNavigationContext.Provider value={value}>{children}</InPageNavigationContext.Provider>
  );
};

function getContext() {
  const context = React.useContext(InPageNavigationContext);
  if (context === null) {
    throw Error('Did you forget to wrap your Feature App in <InPageNavigationProvider>?');
  }
  return context;
}

export function useInPageNavigationService(): InPageNavigationServiceV1 {
  return getContext().inPageNavigationService;
}
